import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/olga-m-martinez.jpg";
  const fullName = `Olga Migdalia Martinez`;
  const zoomUrl =
    "https://us02web.zoom.us/j/86893628056?pwd=S0hUVEllVG9Pb2hENE9HTHBlMkVDdz09";
  const zoomPhoneNumber = "(646) 558-8656";
  const zoomMeetingId = "868 9362 8056";
  const zoomMeetingPasscode = "155310";
  const birthDate = new Date(1944, 5 - 1, 1);
  const deathDate = new Date(2021, 1 - 1, 7);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 17);
  const memorialTime = "3pm EST";
  const inviteText = `Due to the current restrictions we all face and restrictions, in lieu of a physical ceremony, the family and friend’s of Olga Martinez invite you to a virtual service honoring her life.`;
  const obituary = `Olga M. Martinez passed away on January 7, 2021 at the age of 76 after an intense battle with Covid.
  \nMigdalia as she was affectionately known by her friends and family, was born on May 1, 1944 in Barceloneta, Puerto Rico.  She was the daughter of the late Perfecta Figueroa and Manuel Martinez. She had one brother and two sisters who preceded her in death. She is survived by her sister, Carmen Perez and half brother, Hector Martinez.
  \nMigdalia’s life companion, Carlos Vazquez, preceded her in death October 2019. She is survived by their two daughters, Marilyn Vazquez and Aracelis Persaud; son-in-law, Sven Persaud and two grandsons, Xavier Vazquez and Johann Persaud and several nieces, nephews, and cousins. 
  \nMigdalia moved to Newburgh, NY in the 1960’s, where she met her life companion, Carlos Vazquez. They later moved to Miami, FL in 1996 where they lived out the rest of their lives.
  \nMigdalia worked to provide for the family until a disability unfortunately forced her into early retirement. However, retirement did not mean not working. She was a dutiful woman who dedicated herself to taking care of her family. She was also a devoted companion and mother to her special needs daughter, Marilyn.  Even in her later years, Migdalia went above and beyond to provide for Marilyn.
  \nTruly, Migdalia was a woman with a heart of gold.  She opened her home to those in need; always willing to lend a helping hand or tend and comfort those who were ill.
  \nA virtual memorial service will be held on Sunday, January 17, 2021 at 3:00 pm.`;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/Olga%20Migdalia.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="2fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
    >
      <hr />
      <p>En Español</p>
      <p>
        Olga M. Martínez falleció el 7 de enero de 2021 a la edad de 76 años
        luego de una intensa batalla con Covid.
      </p>
      <p>
        Migdalia, como la conocían cariñosamente sus amigos y familiares, nació
        el 1 de mayo de 1944 en Barceloneta, Puerto Rico. Era hija de la difunta
        Perfecta Figueroa y Manuel Martínez. Tenía un hermano y dos hermanas que
        la precedieron en la muerte. Le sobreviven su hermana, Carmen Pérez y su
        medio hermano, Héctor Martínez.
      </p>
      <p>
        El compañero de vida de Migdalia, Carlos Vázquez, la precedió en la
        muerte de octubre de 2019. Le sobreviven sus dos hijas, Marilyn Vazquez
        y Aracelis Persaud; yerno, Sven Persaud y dos nietos, Xavier Vazquez y
        Johann Persaud y varias sobrinas, sobrinos y primos.
      </p>
      <p>
        Migdalia se mudó a Newburgh, NY en la década de 1960, donde conoció a su
        compañero de vida, Carlos Vázquez. Más tarde se mudaron a Miami, FL en
        1996, donde vivieron el resto de sus vidas.
      </p>
      <p>
        Migdalia trabajó para mantener a la familia hasta que, lamentablemente,
        una discapacidad la obligó a jubilarse anticipadamente. Sin embargo,
        jubilarse no significa no trabajar. Fue una mujer obediente que se
        dedicó a cuidar de su familia. También fue una fiel compañera y madre de
        su hija con necesidades especiales, Marilyn. Incluso en sus últimos
        años, Migdalia hizo todo lo posible para mantener a Marilyn.
      </p>
      <p>
        En verdad, Migdalia era una mujer con un corazón de oro. Abrió su casa a
        los necesitados; siempre dispuesto a echar una mano o atender y consolar
        a los enfermos.
      </p>
      <p>
        Se llevará a cabo un servicio conmemorativo virtual el domingo 17 de
        enero de 2021 a las 3:00 pm.
      </p>
    </StoryPage>
  );
};

export default ThisPage;
